body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   /*cursor: none;*/
   /*cursor: url('ruta/al/archivo.svg'), auto;*/
   caret-color: transparent; /* Hace que el cursor sea transparente */
   
   background-image: url("./resources/background.jpg");   
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
 
  height: 100vh;
  overflow-y: scroll; /* Habilita el desplazamiento vertical dentro del contenedor */
}

.bloque {
  height: 500px;
}
.bg-danger-1{
  background-color: rgb(224, 9, 31)!important;
}
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.bg-custom {
  /* background-color: #e0091fff;*/
  background-color: #ba0026ff;
}

.transparencia {
  --bs-bg-opacity: 0.7;
}
/*----------------------animaciones---------------------*/
.fade-in {
  opacity: 0;
  animation: fadeInAnimation 1s ease-in-out forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.btn-icon {
  position: relative;
  overflow: hidden;
}

.btn-icon span {
  transition: transform 0.3s ease-in-out;
}

.btn-icon:hover span {
  transform: scale(1.2);
}
/*----------------------animaciones2---------------------*/
@keyframes fade-in-sequence {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-sequence > * {
  animation: fade-in-sequence 0.3s ease-in-out forwards;
  opacity: 0;
}

.animate-sequence > *:nth-child(1) {
  animation-delay: 0s;
}

.animate-sequence > *:nth-child(2) {
  animation-delay: 0.5s;
}

.animate-sequence > *:nth-child(3) {
  animation-delay: 0.10s;
}

.animate-sequence > *:nth-child(4) {
  animation-delay: 0.20s;
}
/*animacion 3*/
@keyframes psychedelic-effect {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.psychedelic-animation:hover {
  animation: psychedelic-effect 3s linear infinite;
}

/*botones*/
.btn-lg.btn-icon {
  padding: 0.6rem 1rem;
  font-size: 2.5rem;
  line-height: 1;
}

.color-transition {
  transition: color 0.3s ease;
}

.color-transition:hover {  
  color: #f30303;
}

.btn-icon {
  position: relative;
}

.btn-icon:hover span {
  animation: color-transition 0.5s infinite;
}

@keyframes color-transition {
  0% {
    color: #000;
  }
  50% {
    color: #FFD700;
  }
  100% {
    color: #000;
  }
}